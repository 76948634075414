import React, {useEffect, ReactNode} from 'react'
import clsx from 'clsx'
import classes from './styles.module.css'

interface ModalProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  customClassName?: string
}

const Modal: React.FC<ModalProps> = ({children, isOpen, onClose, customClassName}) => {
  useEffect(() => {
    const handleKeyup = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose()
      }
    }

    if (isOpen) {
      window.addEventListener('keyup', handleKeyup)
    }
    return () => {
      window.removeEventListener('keyup', handleKeyup)
    }
  }, [isOpen, onClose])

  if (!isOpen) return null

  return (
    <div className={clsx(classes.customModal, customClassName)} onClick={onClose}>
      <div className={classes.modalContent} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default Modal
