'use client'
import {useState} from 'react'
import clsx from 'clsx'
import Image, {StaticImageData} from 'next/image'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'

import {footerTestimonialData} from 'v1/content/footer'
import quoteImg from 'v1/assets/icons/footer/quote-icon-white.svg'

import 'swiper/css'
import './styles.css'

import classes from './styles.module.css'

export default function Review({showExtraSection = false}) {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div className={classes.container} style={{marginTop: showExtraSection ? '240px' : '0px'}}>
      <h2 className={clsx('h3-v1', classes.header2)}>{footerTestimonialData.title}</h2>
      <div className={classes.reviewCardContainer}>
        <Swiper
          spaceBetween={10}
          loop={true}
          onSlideChange={swiper => setActiveIndex(swiper.realIndex)}
          modules={[]}
          className="mySwiper"
        >
          {footerTestimonialData.cards.map((card, idx) => (
            <SwiperSlide key={idx}>
              <ReviewCard card={card} />
            </SwiperSlide>
          ))}
          <ChangeSlide activeIndex={activeIndex} />
        </Swiper>
      </div>
    </div>
  )
}

interface ReviewCardProps {
  card: {
    text: string
    img: StaticImageData
    position: string
    companyLogo: StaticImageData
  }
}
const ReviewCard = ({card}: ReviewCardProps) => {
  return (
    <div className={classes.singleCard} key={card.position}>
      <div className={classes.imageContainer}>
        <Image className={classes.clientImage} src={card.img} alt="client" />
      </div>
      <div className={classes.detailsContainer}>
        <div>
          <Image className={classes.quoteImage} src={quoteImg} alt="quote" />
          <p className={clsx('h4-v1', classes.description)}> {card.text}</p>
        </div>
        <div>
          <div className={classes.company}>
            <p
              className={clsx('paragraph1-v1', classes.position)}
              dangerouslySetInnerHTML={{__html: card.position}}
            ></p>
            <div className={classes.mobileBottomLine}></div>
            <Image src={card.companyLogo} alt="logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

const ChangeSlide = ({activeIndex}: {activeIndex: number}) => {
  const swiper = useSwiper()

  return (
    <div className={classes.cardLogoImageContainer}>
      {footerTestimonialData?.cards?.map((card, idx) => (
        <div key={idx} className={classes.cardLogo}>
          <span
            className={clsx(classes.cardLogoImage, {[classes.activeLogo]: activeIndex === idx})}
          >
            <Image onClick={() => swiper.slideTo(idx)} src={card.companyLogo} alt="logo" />
            {activeIndex === idx && <span className={classes.logoBottomLine}></span>}
          </span>
          {idx !== footerTestimonialData?.cards?.length - 1 && (
            <span className={classes.rightLine}></span>
          )}
        </div>
      ))}
    </div>
  )
}
