// hooks/useReCaptchaVerification.ts
import {useState} from 'react'
import {useReCaptcha} from 'next-recaptcha-v3'
import {
  verifyReCaptcha,
  ReCaptchaError,
  getPublicErrorMessage,
  VERIFICATION_ERRORS,
} from 'utils/recaptcha'
import type {ReCaptchaVerificationResult} from 'types/recaptcha'

interface UseReCaptchaVerificationResult {
  verifyUser: (action: string) => Promise<ReCaptchaVerificationResult>
  isVerifying: boolean
  error: string | null
  clearError: () => void
}

export function useReCaptchaVerification(): UseReCaptchaVerificationResult {
  const {executeRecaptcha, loaded} = useReCaptcha()
  const [isVerifying, setIsVerifying] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const clearError = () => setError(null)

  const verifyUser = async (action: string): Promise<ReCaptchaVerificationResult> => {
    if (!executeRecaptcha || !loaded) {
      throw new ReCaptchaError(VERIFICATION_ERRORS.NOT_INITIALIZED)
    }

    try {
      setIsVerifying(true)
      setError(null)

      const token = await executeRecaptcha(action)
      return await verifyReCaptcha(token)
    } catch (err) {
      const errorMessage = getPublicErrorMessage(err)
      setError(errorMessage)
      throw err
    } finally {
      setIsVerifying(false)
    }
  }

  return {verifyUser, isVerifying, error, clearError}
}
