import axios from 'axios'
const webhookUrl =
  'https://hooks.slack.com/services/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'

export const sendLeadDetailsToSlack = async (data: any, callback?: any) => {
  const payload = {
    text: `New submission details: 
    - *Full Name*: ${data.fullName} 
    - *Email*: ${data.workEmail} 
    - *Phone Number*: ${data.phoneNumber} 
    - *Country Code*: ${data.countryCode} 
    - *Country Name*: ${data.countryName}
    - *Company Name*: ${data.companyName} 
    - *Number of Employees*: ${data.employees}
    - *URL Path*: ${data?.urlPath}`
  };
  
  try {
    const response = await axios.post(webhookUrl, JSON.stringify(payload), {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          if (headers.post) delete headers.post['Content-Type']
          return data
        },
      ],
    })
    if (callback) callback()

    if (response.status !== 200) {
      throw new Error('Failed to send message to Slack')
    }
  } catch (error) {
    console.error('Error sending to Slack:', error)
  }
}
