//* Product Routes
export const HYBR1D_PLATFORM = '/product/zenadmin-platform'
export const EMPLOYEE_LIFECYCLE_MANAGEMENT = '/product/employee-lifecycle-management'
export const APP_MANAGEMENT = '/product/app-management'
export const DEVICE_MANAGEMENT = '/product/mobile-device-management'
export const IDENTITY_MANAGEMENT = '/product/identity-management'
export const ADDITIONAL_SERVICES = '/product/it-helpdesk-support'
export const INVENTORY_MANAGEMENT = '/product/inventory-management'
export const PEOPLE_MANAGEMENT = '/product/people-management'
export const DEVICE_LIFECYCLE_MANAGEMENT = '/product/device-lifecycle-management'
export const IT_ASSET_MANAGEMENT = '/product/it-asset-management'

//* Free Trial
export const IT_ASSET_MANAGEMENT_FREE_TRIAL = '/free-trial/it-asset-management'
export const IT_SUPPORT_FREE_TRIAL = '/free-trial/it-support'


//* Solutions Routes
export const FOR_FOUNDERS_HR_AND_IT_ADMINS = '/solutions/for-founders-hr-and-it-admins'
export const BECOME_SECURITY_COMPLIANT = '/solutions/become-security-compliant'
export const SAVE_COSTS_AND_ESTABLISH_SCALABLE_PROCESSES =
  '/solutions/save-costs-establish-scalable-processes'

//* Company Routes
export const ABOUT_US = '/company/about-us'
export const CAREERS = 'https://wellfound.com/company/hybr1d'

export const PRICING = '/pricing'
export const WHY_HYBR1D = '/why-zenadmin'
export const BLOG = '/blogs'

// policy link
export const PRIVACY_POLICY = '/privacy-policy'
export const TERMS_OF_SERVICE = '/terms-of-service'
export const PERSONAL_DATA_PROCESSING_AGREEMENT = '/personal-data-processing-agreement'
export const COOKIES_SETTINGS = '/cookies-settings'
//Links
export const HYBRID_APP = 'https://console.zenadmin.ai'
export const DEMO_LINK = 'https://meetings-eu1.hubspot.com/rupesh-kumar/zenadmin-demo-new'
