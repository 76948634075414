import {ReCaptchaVerificationResult} from 'types/recaptcha'

export class ReCaptchaError extends Error {
  constructor(message: string, public readonly code?: string, public readonly score?: number) {
    super(message)
    this.name = 'ReCaptchaError'
  }
}

export const VERIFICATION_ERRORS = {
  NETWORK: 'Failed to connect to verification service',
  INVALID_RESPONSE: 'Invalid verification response',
  TIMEOUT: 'Verification request timed out',
  LOW_SCORE: 'Security check score too low',
  NOT_INITIALIZED: 'ReCAPTCHA not initialized',
} as const

export async function verifyReCaptcha(token: string): Promise<ReCaptchaVerificationResult> {
  if (!token) {
    throw new ReCaptchaError('Missing reCAPTCHA token')
  }

  try {
    const response = await fetch('/api/verify-recaptcha', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({recaptchaToken: token}),
    })

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}))
      throw new ReCaptchaError(
        errorData.error || VERIFICATION_ERRORS.INVALID_RESPONSE,
        errorData.errorCodes?.[0],
      )
    }

    const result = await response.json()

    if (!result.success) {
      throw new ReCaptchaError(
        result.error || VERIFICATION_ERRORS.INVALID_RESPONSE,
        result.errorCodes?.[0],
        result.score,
      )
    }

    return result
  } catch (error) {
    if (error instanceof ReCaptchaError) {
      throw error
    }
    throw new ReCaptchaError(VERIFICATION_ERRORS.NETWORK)
  }
}

export function getPublicErrorMessage(error: unknown): string {
  if (error instanceof ReCaptchaError) {
    switch (error.code) {
      case 'TIMEOUT':
        return 'Verification timed out. Please try again.'
      case 'NETWORK':
        return 'Unable to complete security check. Please try again later.'
      default:
        return error.message
    }
  }
  return 'An unexpected error occurred. Please try again.'
}
